import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ConversationViewComponent from "../../components/ConversationViewComponent";
import MessageListComponent from "../../components/MessageListComponent";
import MessageInputComponent from "../../components/MessageInputComponent";
import { getDatabase, ref, push, set, get } from "firebase/database";
import "./Messages.css";
import { useAuthContext } from "../../hooks/useAuthContext";

const findOrCreateConversation = async (user1, user2) => {
  const db = getDatabase();
  let conversationId = null;

  // Check for an existing conversation between user1 and user2
  const userConversationsRef = ref(db, `userConversations/${user1}`);
  const snapshot = await get(userConversationsRef);

  if (snapshot.exists()) {
    const conversations = snapshot.val();
    for (const [key, value] of Object.entries(conversations)) {
      const conversationSnapshot = await get(ref(db, `conversations/${key}`));
      if (conversationSnapshot.exists()) {
        const conversationData = conversationSnapshot.val();
        if (conversationData.participants[user2]) {
          conversationId = key;
          break;
        }
      }
    }
  }

  if (!conversationId) {
    // Create a new conversation only if it's not already in progress
    const conversationCreationInProgressRef = ref(
      db,
      `conversationCreationInProgress/${user1}-${user2}`
    );

    const conversationCreationInProgressSnapshot = await get(
      conversationCreationInProgressRef
    );

    if (!conversationCreationInProgressSnapshot.exists()) {
      // Set the flag to indicate that conversation creation is in progress
      await set(conversationCreationInProgressRef, true);

      // Create a new conversation
      const newConversationRef = push(ref(db, "conversations"));
      const newConversationId = newConversationRef.key;

      await set(newConversationRef, {
        participants: {
          [user1]: true,
          [user2]: true,
        },
        messages: {},
      });

      // Update userConversations for each participant
      await set(
        ref(db, `userConversations/${user1}/${newConversationId}`),
        true
      );
      await set(
        ref(db, `userConversations/${user2}/${newConversationId}`),
        true
      );

      // Remove the flag to indicate that conversation creation is complete
      await set(conversationCreationInProgressRef, null);

      return newConversationId;
    }
  }

  return conversationId;
};

const Messages = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const User1 = useAuthContext().user?.uid;
  const User2 = queryParams.get("User2");
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const isConversationCreationInProgress = useRef(false);

  useEffect(() => {
    if (User1 && User2 && !isConversationCreationInProgress.current) {
      isConversationCreationInProgress.current = true;
      findOrCreateConversation(User1, User2)
        .then((conversationId) => {
          setSelectedConversationId(conversationId);
          isConversationCreationInProgress.current = false;
        })
        .catch((error) => {
          console.error("Error setting conversation ID:", error);
          isConversationCreationInProgress.current = false;
        });
    }
  }, [User1, User2]);

  const handleSelectConversation = (conversationId) => {
    setSelectedConversationId(conversationId);
  };
  return (
    <div className="messages-container">
      {/* Sidebar for Message List */}
      <div className="sidebar">
        <MessageListComponent
          onSelectConversation={handleSelectConversation}
          selectedConversationId={selectedConversationId} // Pass the selectedConversationId
        />
      </div>

      {/* Main Chat Area */}
      <div className="main-chat mt-10">
        {selectedConversationId ? (
          <div className="chat-container">
            <ConversationViewComponent
              conversationId={selectedConversationId}
              currentUserId={User1}
            />
            <MessageInputComponent
              className="mx-3"
              conversationId={selectedConversationId}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center mx-3 mt-5 h-full">
            <span>Select a conversation to start chatting</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
