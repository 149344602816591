import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Container,
  Paper,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import FlourishEmbedFb from "../../components/FlourishEmbedFb";
import FlourishEmbedTw from "../../components/FlourishEmbedTw";
import FlourishEmbedCl from "../../components/FlourishEmbedCl";

// Define the keyframes for the up and down animation
const upAndDown = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.primary.contrastText,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: "16px",
});

const faqs = [
  {
    question: "How is data sourced and stored on your platform?",
    answer:
      "Our platform primarily functions as an information aggregator and facilitator. We do not store personal data from external sources like Facebook. Instead, we provide links or URLs to external posts, redirecting users to the original source. This ensures that we do not hold or manage external personal data, maintaining a lean and secure data management approach.",
  },
  {
    question:
      "How accurate is the location information for lost or found items on your platform?",
    answer:
      "The accuracy of location information is dependent on the details provided by the users who post about lost or found items. We encourage our users to be as precise as possible when sharing location details. However, we do not independently verify these locations, so we recommend users exercise due diligence and verify the accuracy of location information during their communication.",
  },
  {
    question: "Who holds the liability for the verification of returned items?",
    answer:
      "Our platform acts as a facilitator of information exchange between individuals who have lost items and those who have found them. We do not take part in the physical exchange of items and, therefore, do not hold any liability for the verification of returned items. The responsibility of verifying the authenticity and condition of the items lies solely between the individual who lost the item and the individual who found it.",
  },
  {
    question: "What are the GDPR compliance measures taken by your platform?",
    answer:
      "We are committed to upholding the principles of the General Data Protection Regulation (GDPR). Our platform ensures that all personal data is processed in accordance with the rights of the data subjects. We do not store personal data from external sources and encourage minimal data sharing. Users have the right to access their data, request correction, or deletion of their personal data as per GDPR guidelines.",
  },
  {
    question: "Can I report a post or a user on your platform?",
    answer:
      "Yes, you can report any post or user that seems fraudulent, misleading, or violates our community guidelines. We take such reports seriously and will take appropriate action to ensure the safety and integrity of our platform.",
  },
  {
    question: "How can I update or delete my post on the platform?",
    answer:
      "Users can update or delete their posts at any time. Simply log into your account, navigate to your post, and you will find options to either edit or delete it. We encourage users to delete their posts once their lost item has been found or returned.",
  },
];

const FAQSection = () => (
  <Box sx={{ p: 10 }}>
    <Typography
      variant="h4"
      component="h2"
      fontWeight="bold"
      textAlign="center"
      marginBottom={3}
    >
      Frequently Asked Questions
    </Typography>
    <Box>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            backgroundColor: "background.paper",
            boxShadow: "none",
            "&:before": { display: "none" },
            "&:not(:last-child)": { borderBottom: 0 },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            sx={{
              backgroundColor: "#00a6fb",
              color: "primary.contrastText",
              ".MuiAccordionSummary-expandIconWrapper": {
                color: "primary.contrastText",
              },
            }}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "16px" }}>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  </Box>
);

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        textAlign: "center",
        marginTop: 20,
      }}
    >
      <Box
        sx={{
          p: 10,
        }}
      >
        <Typography variant="h4" component="h2">
          Find your lost items & help others find theirs.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            animation: `${upAndDown} 1s ease-in-out infinite`,
          }}
          onClick={() => navigate("/login")}
        >
          Log In
        </Button>
      </Box>
      <Box
        sx={{
          backgroundImage: `url('/landing_map.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          p: 25,
          bgcolor: "#202020",
          color: "#FFFFFF",
          borderRadius: 2,
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.4)",
          transition: "box-shadow 0.3s",
        }}
      >
        {/* Map content */}
      </Box>

      <Box
        sx={{
          marginTop: 15,
        }}
      >
        <FlourishEmbedFb />
      </Box>
      <Box
        sx={{
          marginTop: 15,
        }}
      >
        <FlourishEmbedTw />
      </Box>
      <Box
        sx={{
          marginTop: 15,
        }}
      >
        <FlourishEmbedCl />
      </Box>
    </Box>
  );
};

const mapsLogo = "Google-Maps-logo.png";
const mapsLogo2 = "maps_logo.png";

const FeatureCard = ({ title, description, logos }) => (
  <Card
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: 6,
      "&:hover": {
        boxShadow: 10,
        transform: "scale(1.05)",
        transition: "transform .2s ease-in-out",
      },
      m: 2,
      p: 2,
      borderRadius: 2,
      textAlign: "center",
      height: "100%",
    }}
  >
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          flexWrap: "wrap",
        }}
      >
        {logos.map((logo, index) => (
          <CardMedia
            key={index}
            component="img"
            image={logo}
            alt={`Logo ${index}`}
            sx={{
              width: "auto",
              height: 60,
              m: 1,
            }}
          />
        ))}
      </Box>
    </CardContent>
  </Card>
);

const FeaturesSection = () => (
  <Box sx={{ flexGrow: 1, p: 4, marginTop: 1 }}>
    <Typography variant="h4" align="center" mb={4}>
      Features
    </Typography>
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} sm={6}>
        <FeatureCard
          title="Aggregate Information"
          description="Gathers data from various platforms like Facebook, Twitter, and Craigslist for comprehensive search results."
          logos={[
            "landing_fb_logo.png",
            "landing_twitter_logo.png",
            "cl_logo.png",
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FeatureCard
          title="Geolocation & Mapping"
          description="Quickly report lost items with geolocation and view nearby matches on interactive maps."
          logos={[mapsLogo]}
        />
      </Grid>
    </Grid>
  </Box>
);

// const AboutSection = () => (
//   <Box sx={{ p: 10 }}>
//     <Typography
//       variant="h4"
//       component="h2"
//       fontWeight="bold"
//       textAlign="center"
//     >
//       About Us
//     </Typography>
//     {/* Content about your company */}
//   </Box>
// );

// const TestimonialsSection = () => (
//   <Box sx={{ p: 10 }}>
//     <Typography
//       variant="h4"
//       component="h2"
//       fontWeight="bold"
//       textAlign="center"
//     >
//       Testimonials
//     </Typography>
//     {/* Testimonial cards or quotes */}
//   </Box>
// );

// const FAQSection = () => (
//   <Box sx={{ p: 10 }}>
//     <Typography
//       variant="h4"
//       component="h2"
//       fontWeight="bold"
//       textAlign="center"
//     >
//       FAQs
//     </Typography>
//     {/* FAQ items */}
//   </Box>
// );

// const ContactSection = () => (
//   <Box sx={{ p: 10 }}>
//     <Typography
//       variant="h4"
//       component="h2"
//       fontWeight="bold"
//       textAlign="center"
//     >
//       Contact Us
//     </Typography>
//     {/* Contact form or information */}
//   </Box>
// );

// const Footer = () => (
//   <Paper
//     component="footer"
//     sx={{ bgcolor: "grey.200", p: 4, textAlign: "center" }}
//   >
//     {/* Footer content */}
//   </Paper>
// );

const LandingPage = () => (
  <Container maxWidth="lg">
    <HeroSection />
    <FeaturesSection /> {/* Correct usage as JSX tag */}
    <FAQSection />
    {/* <TestimonialsSection />
    <FAQSection />
    <AboutSection />
    <ContactSection />
    <Footer /> */}
  </Container>
);

export default LandingPage;
