import React, { useState, useEffect } from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import useLogout from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

function Navbar() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const [mobileOpen, setMobileOpen] = useState(null);
  const [scrollingUp, setScrollingUp] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollingUp(scrollTop === 0 || scrollTop <= window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollingUp]);

  const handleDrawerToggle = (event) => {
    setMobileOpen(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setMobileOpen(null);
  };

  const appBarStyle = {
    boxShadow: "none",
    borderBottom: "none",
    transition: "background-color 0.3s, transform 0.3s ease",
    transform: scrollingUp ? "translateY(0)" : "translateY(-100%)",
  };

  const buttonStyle = {
    color: "#fff",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    fontSize: "0.9rem",
    letterSpacing: "0.05em",
    textDecoration: "none",
  };

  const popoverStyle = {
    "& .MuiPaper-root": {
      color: "#202020", // Set font color
    },
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar>
          <Button component={RouterLink} to="/">
            <img
              src="rf_logo_small.png"
              alt="Logo"
              style={{ height: "40px" }}
            />
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, color: "#fff" }}
            >
              Refind
            </Typography>
          </Button>

          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              marginLeft: "auto",
              color: "#fff",
            }}
          >
            {!user ? (
              <>
                <Button component={RouterLink} to="/login" style={buttonStyle}>
                  Login
                </Button>
                <Button component={RouterLink} to="/signup" style={buttonStyle}>
                  Sign Up
                </Button>
              </>
            ) : (
              <>
                <Button
                  component={RouterLink}
                  to="/messages"
                  style={buttonStyle}
                >
                  Messages
                </Button>
                <Button
                  component={RouterLink}
                  to="/myitems"
                  style={buttonStyle}
                >
                  My Items
                </Button>
                <Button onClick={logout} style={buttonStyle}>
                  Sign Out
                </Button>
              </>
            )}
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" }, marginLeft: "auto" }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Popover
        open={Boolean(mobileOpen)}
        anchorEl={mobileOpen}
        onClose={handleDrawerClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right", // Change anchor to "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right", // Change transform to "right"
        }}
        sx={popoverStyle}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            p: 2,
            backgroundColor: "#292929",
          }}
        >
          {!user ? (
            <>
              <Button component={RouterLink} to="/login" style={buttonStyle}>
                Login
              </Button>
              <Button component={RouterLink} to="/signup" style={buttonStyle}>
                Sign Up
              </Button>
            </>
          ) : (
            <>
              <Button component={RouterLink} to="/messages" style={buttonStyle}>
                Messages
              </Button>
              <Button component={RouterLink} to="/myitems" style={buttonStyle}>
                My Items
              </Button>
              <Button onClick={logout} style={buttonStyle}>
                Sign Out
              </Button>
            </>
          )}
        </Box>
      </Popover>
    </Box>
  );
}

export default Navbar;
