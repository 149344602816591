import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../hooks/useFirestore";
import { useGoogleAutocomplete } from "../hooks/useGoogleAutocomplete";
import useImageUpload from "../hooks/useImageUpload";
import "./ReportItemModal.css";
import Compressor from "compressorjs";
import { useGoogleApi } from "../hooks/useGoogleApi";
import { Timestamp } from "firebase/firestore";

function ReportItemModal({ isActive, toggleModal, userId, userLocation }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPending, setIsPending] = useState(false);

  // In ReportItemModal component
  const apiLoaded = useGoogleApi();

  const navigate = useNavigate(); // Add this line to get the navigate function

  const {
    // destructuring the useGoogleAutocomplete hook
    autocompleteLocation,
    coordinates,
    country,
    handlePlaceSelect,
    setAutocompleteLocation,
  } = useGoogleAutocomplete();

  // for image upload
  const [image, setImage] = useState(null);
  const [fileError, setFileError] = useState(null);
  const {
    url: imageUrl,
    progress,
    error: uploadError,
  } = useImageUpload(image, userId, country);

  const handleFileChange = (e) => {
    let selected = e.target.files[0];
    const types = ["image/png", "image/jpeg", "image/gif"];

    if (selected && types.includes(selected.type)) {
      new Compressor(selected, {
        quality: 0.8, // Compression quality (0 to 1; 1 being the highest quality)
        maxWidth: 800, // Set the maximum width of the image
        maxHeight: 600, // Set the maximum height of the image
        convertSize: 0, // Convert all images to JPEGs if size exceeds this value (0 disables conversion)
        success(compressedImage) {
          // Set the compressed image to state
          setImage(compressedImage);
          setFileError(null);
        },
        error(err) {
          console.error("Compression Error:", err.message);
          setFileError("Error occurred while compressing the image.");
        },
      });
    } else {
      setImage(null);
      // setFileError("Please select an image file (png, jpeg, gif)");
    }
  };

  const { addDocument, response, error } = useFirestore("items"); // using the useFirestore hook & destructuring the response object

  const categories = {
    Uncategorized: 1,
    Phone: 2,
    Dog: 3,
    Wallet: 4,
    Passport: 5,
    Sunglasses: 6,
    Laptop: 7,
    Ring: 8,
    Glasses: 9,
    Bag: 10,
    License: 11,
    Cat: 12,
    Watch: 13,
    Documents: 14,
  };

  useEffect(() => {
    // Check if all required fields are filled out
    if (
      name &&
      autocompleteLocation &&
      status &&
      category &&
      (progress === 100 || progress === 0)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, autocompleteLocation, status, category]);

  useEffect(() => {
    if (apiLoaded) {
      // Initialize Autocomplete only if the API is loaded
      const autocompleteInput = document.getElementById("autocompleteInput");
      if (autocompleteInput) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          autocompleteInput
        );

        autocomplete.addListener("place_changed", () =>
          handlePlaceSelect(autocomplete)
        );

        return () => {
          // Cleanup listener on component unmount
          window.google.maps.event.clearInstanceListeners(autocomplete);
        };
      }
    }
  }, [apiLoaded, handlePlaceSelect]); // Depend on apiLoaded

  // main submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear previous errors
    setSuccessMessage(""); // Clear previous success message
    setIsPending(true); // Start loading

    if (uploadError) {
      setErrorMessage("Image upload failed. Please try again.");
      setIsPending(false);
      return;
    }

    // Convert 'lost'/'found' to 0/1
    const statusInt = status === "lost" ? 0 : 1;
    // Determine the category ID based on the category name
    const categoryCode = categories[category] || null;

    // Ensure coordinates are defined
    if (
      !coordinates ||
      typeof coordinates.lat === "undefined" ||
      typeof coordinates.lng === "undefined"
    ) {
      setErrorMessage(
        "Location coordinates are not available. Please try again."
      );
      setIsPending(false);
      return;
    }

    const itemData = {
      name, // Captured from form
      description, // Captured from form
      location: autocompleteLocation, // Derived from user input or other logic
      date: new Date(date).toISOString(), // Convert to ISO 8601 string format
      category: category.toLowerCase(), // Lowercased category name
      categoryCode, // Category code (ensure this is set correctly)
      status: statusInt, // Status converted to integer (0 or 1)
      uid: userId, // User ID
      latitude: coordinates.lat, // Latitude value from coordinates
      longitude: coordinates.lng, // Longitude value from coordinates
      imageUrl, // URL of the uploaded image
      url: null, // Null if URL is not applicable
      source: 100, // Source code indicating the origin of the entry
    };

    console.log("Item data:", itemData);

    try {
      await addDocument(itemData);
      setSuccessMessage("Item reported successfully!");
      setTimeout(() => {
        setSuccessMessage("");
        toggleModal();
        navigate("/myitems");
      }, 2000);
    } catch (error) {
      setErrorMessage(error.message || "An unexpected error occurred.");
      setIsPending(false);
    }
  };

  return (
    <div className={`modal ${isActive ? "is-active" : ""}`}>
      <div className="modal-background" onClick={toggleModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Report Item</p>
          <button
            className="delete"
            aria-label="close"
            onClick={toggleModal}
          ></button>
        </header>
        <section className="modal-card-body">
          {isPending ? ( // Conditionally render loader if isPending is true
            <div className="loader-wrapper">
              <div className="loader"></div>
              <p>Loading...</p>
            </div>
          ) : (
            // Render form elements when isPending is false
            <>
              {errorMessage && (
                <div className="notification is-danger">{errorMessage}</div>
              )}
              {successMessage && (
                <div className="notification is-success">{successMessage}</div>
              )}

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label" htmlFor="statusInput">
                      * Status (Lost/Found)
                    </label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="statusInput"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          required
                        >
                          <option value="">Select a status</option>
                          <option value="lost">Lost</option>
                          <option value="found">Found</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label" htmlFor="categoryInput">
                      * Category
                    </label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="categoryInput"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          required
                        >
                          <option value="">Select a category</option>
                          {Object.keys(categories).map((cat) => (
                            <option key={cat} value={cat}>
                              {cat}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label" htmlFor="nameInput">
                      * Item Name
                    </label>
                    <div className="control">
                      <input
                        id="nameInput"
                        className="input"
                        type="text"
                        placeholder="Name of the item"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label" htmlFor="dateInput">
                      Date
                    </label>
                    <div className="control">
                      <input
                        id="dateInput"
                        className="input"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        max={new Date().toISOString().split("T")[0]}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="autocompleteInput">
                  * Location
                </label>
                <div className="control">
                  <input
                    id="autocompleteInput"
                    className="input"
                    type="text"
                    placeholder="Location of the item"
                    value={autocompleteLocation}
                    onChange={(e) => setAutocompleteLocation(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Image</label>
                <div className="control">
                  <div className="file has-name is-fullwidth">
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="resume"
                        onChange={handleFileChange}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">Choose a file…</span>
                      </span>
                      {image && <span className="file-name">{image.name}</span>}
                    </label>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="itemDescription">
                  Description
                </label>
                <div className="control">
                  <textarea
                    id="itemDescription"
                    className="textarea"
                    placeholder="Description of the item"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </>
          )}
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button ${!isFormValid && "is-static"}`}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button className="button" onClick={toggleModal}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default ReportItemModal;
