import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useState, useEffect } from "react"; // Import useState and useEffect
import "bulma/css/bulma.min.css";

// pages & components
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Navbar from "./components/Navbar";
import Myitems from "./pages/myitems/Myitems";
import Messages from "./pages/messages/Messages";
import Landing from "./pages/landing/Landing";

import "./App.css";

function App() {
  const { authIsReady, user } = useAuthContext();
  const [scrollingUp, setScrollingUp] = useState(true); // State to track scroll direction

  // Function to handle scroll event
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setScrollingUp(currentScrollPos < window.scrollY);
  };

  // Add a scroll event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Remove event listener when unmounting
    };
  }, []);

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          {/* Pass the scrollingUp state as a prop to Navbar */}
          <Navbar scrollingUp={scrollingUp} />
          <Routes>
            {/* Show LandingPage for the root route if not logged in */}
            <Route
              path="/"
              element={user ? <Navigate to="/home" /> : <Landing />}
            />

            {/* If the user is logged in */}
            {user ? (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/myitems" element={<Myitems />} />
                <Route path="/messages" element={<Messages />} />
                <Route
                  path="/login"
                  element={<Navigate to="/home" replace />}
                />
                <Route
                  path="/signup"
                  element={<Navigate to="/home" replace />}
                />
              </>
            ) : (
              <>
                {/* Routes for non-logged-in users */}
                {!user && (
                  <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/home" element={<Login />} />
                    <Route path="/messages" element={<Login />} />
                    <Route path="/myitems" element={<Login />} />
                  </>
                )}
              </>
            )}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
