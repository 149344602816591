import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

const ConversationViewComponent = ({ conversationId, currentUserId }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const messagesRef = ref(db, `conversations/${conversationId}/messages`);
    console.log(currentUserId);

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const loadedMessages = data
        ? Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }))
        : [];
      setMessages(loadedMessages.sort((a, b) => a.timestamp - b.timestamp));
    });

    return () => unsubscribe();
  }, [conversationId]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden conversation-messages">
      <div className="flex-1 overflow-y-auto mb-4 p-4">
        {messages.length > 0 ? (
          messages.map((message) => (
            <div
              key={message.id}
              className={`flex mb-2 ${
                message.senderId === currentUserId
                  ? "justify-end"
                  : "justify-start"
              }`}
            >
              <div
                className={`message-bubble ${
                  message.senderId === currentUserId
                    ? "message-bubble-sender"
                    : "message-bubble-receiver"
                }`}
              >
                <span className="whitespace-pre-wrap">{message.text}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">
            No messages in this conversation.
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationViewComponent;
