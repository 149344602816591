import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-gIae2ADsaUe2bkWbB6i6zln1EkG3wq0",
  authDomain: "refind-1-557ec.firebaseapp.com",
  projectId: "refind-1-557ec",
  storageBucket: "refind-1-557ec.appspot.com",
  messagingSenderId: "258911230505",
  appId: "1:258911230505:web:a77a90fd34c3ec951c1a38",
  measurementId: "G-XQQHD7N3ZP",
  databaseURL:
    "https://refind-1-557ec-default-rtdb.europe-west1.firebasedatabase.app",
};

// init firebase
const app = initializeApp(firebaseConfig);

// init firestore service
const projectFirestore = getFirestore(app);
const projectAuth = getAuth(app);
const projectStorage = getStorage(app);

// export the firestore service
export { projectFirestore, projectAuth, Timestamp, projectStorage };
