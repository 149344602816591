import { useState, useEffect } from "react";
import { projectStorage } from "../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const useImageUpload = (file, userId, country) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (file && country) {
      // Generate a unique filename using userId, country, and a timestamp

      const timestamp = Date.now();
      const uniqueName = `${country}/${userId}_${timestamp}_${file.name}`;
      const storageRef = ref(projectStorage, `uploads/${uniqueName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(percentage);
          // Log progress
          console.log(`Upload Progress: ${percentage}%`);
        },
        (err) => {
          setError(err);
          // Log error
          console.error("Upload Error:", err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            setUrl(downloadUrl);
            // Log download URL
            console.log("Download URL:", downloadUrl);
          });
        }
      );
    }
  }, [file, userId, country]); // Include country in the dependencies array

  return { progress, url, error };
};

export default useImageUpload;
