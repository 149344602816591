import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box"; // Add this line to your existing imports
import { keyframes } from "@emotion/react";
import ReactConfetti from "react-confetti";

import ReportItemModal from "../../modals/ReportItemModal";

import { useAuthContext } from "../../hooks/useAuthContext";
import { grid } from "ldrs";

// Define the keyframes for the up and down animation
const upAndDown = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

// Define the keyframes for the flickering animation
const flickerAnimation = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export default function Home() {
  grid.register();

  const [isModalActive, setModalActive] = useState(false);
  const { user } = useAuthContext();
  const [userLoc, setUserLoc] = useState(null); // this is the one that will have either from google api or default as fallback
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    // Stop the confetti after 5 seconds
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 10000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const toggleModal = () => setModalActive(!isModalActive);

  return (
    <Container maxWidth={false}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ width: "100%", mt: 20, mb: 10 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                animation: `${upAndDown} 1s ease-in-out infinite`,
              }}
              onClick={toggleModal}
              fullWidth
            >
              Report your item
            </Button>
            {
              <span className="infoTextRed">
                {" "}
                *Please note - To prevent abuse, we are limiting the number of
                lost item report you can do in a quarter to 2-3. Please use the
                platform for genuine reports only. Thanks :)
              </span>
            }
          </Box>
        </Grid>
        {/*  Full width map component for heat map */}
        <Grid
          item
          xs={12}
          // md={3}
          sx={{
            position: "relative", // Set position to relative for the container

            display: "flex",
            flexDirection: "column",
            backgroundImage: `url('/home_map.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: {
              xs: 200, // minHeight for extra-small screens
              sm: 400, // minHeight for small screens
              md: 1000, // minHeight for medium screens and up
            },
            borderRadius: 2,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.4)",
            transition: "box-shadow 0.3s",
          }}
        >
          {/* Flickering Highlights */}
          <Box
            sx={{
              position: "absolute",
              top: "18%", // Adjust position based on your needs
              left: "50%", // Adjust position based on your needs
              transform: "translate(-50%, -50%)",

              width: 20,
              height: 20,
              backgroundColor: "green",
              borderRadius: "50%",
              animation: `${flickerAnimation} 1s infinite`,
            }}
          ></Box>
          <Box
            sx={{
              position: "absolute",
              top: "44.5%", // Adjust position based on your needs
              left: "71%", // Adjust position based on your needs
              width: 20,
              height: 20,
              backgroundColor: "green",
              borderRadius: "50%",
              animation: `${flickerAnimation} 1s infinite`,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "73%", // Adjust position based on your needs
              left: "25%", // Adjust position based on your needs
              width: 20,
              height: 20,
              backgroundColor: "red",
              borderRadius: "50%",
              animation: `${flickerAnimation} 1s infinite`,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "91%", // Adjust position based on your needs
              left: "72%", // Adjust position based on your needs
              width: 20,
              height: 20,
              backgroundColor: "green",
              borderRadius: "50%",
              animation: `${flickerAnimation} 1s infinite`,
            }}
          />

          {showConfetti && (
            <ReactConfetti
              width={window.innerWidth}
              height={window.innerHeight}
              numberOfPieces={200}
              recycle={false}
              onConfettiComplete={() => setShowConfetti(false)}
            />
          )}
        </Grid>
      </Grid>
      {/* Modals if active */}
      {isModalActive && (
        <ReportItemModal
          isActive={isModalActive}
          toggleModal={toggleModal}
          userId={user?.uid}
        />
      )}
    </Container>
  );
}
