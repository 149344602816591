import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      // main: "#ff5a5f", "#ff70a6", "#3a86ff",
      // main: "#00a6fb",
      main: "#292929",

      // fb6f92 soft pink
    },
    // You can also define other palette options
    // background: {
    //   default: "#202020", // Set your desired background color here
    // },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
