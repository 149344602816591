import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import MapComponent from "../../components/MapComponent";
import { useFirestore } from "../../hooks/useFirestore";
import { useNavigate } from "react-router-dom";
import "./Myitems.css";
import LegendComponent from "../../components/LegendComponent";
import MyItemsTableComponent from "../../components/MyItemsTableComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Typography,
  Switch,
  Box,
} from "@mui/material";

function Myitems() {
  const { user } = useAuthContext();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showMap, setShowMap] = useState(true);
  // State for the confirmation dialog visibility
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [loading, setLoading] = useState(true); // New loading state for matching items fetching

  const [isDataFetched, setIsDataFetched] = useState(false); // New state for user item fetching

  const { fetchUserItems, fetchMatchingItems, matchingItems, userItems } =
    useFirestore("items", user?.uid);

  const { deleteDocument, response, error } = useFirestore("items"); // using the useFirestore hook & destructuring the response object

  // Fetch user items on component mount
  useEffect(() => {
    if (user?.uid && userItems.length === 0) {
      fetchUserItems();
      setIsDataFetched(true); // Set to true once data is fetched
    }
  }, [user?.uid, userItems.length, fetchUserItems]);

  useEffect(() => {
    if (userItems.length > 0) {
      // If selectedItem is null or its id is not in the updated userItems list, update it
      if (
        !selectedItem ||
        !userItems.some((item) => item.id === selectedItem.id)
      ) {
        setSelectedItem(userItems[0]);
      }
    }
  }, [userItems, selectedItem]);

  const formatDate = (dateObject) => {
    if (dateObject && dateObject._seconds) {
      // Create a Date object from the timestamp
      const date = new Date(dateObject._seconds * 1000);
      // Format the date as a string (e.g., 'Jan 1, 2020')
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
    return "";
  };

  useEffect(() => {
    if (selectedItem) {
      setLoading(true); // Ensure this gets set to true
      fetchMatchingItems({
        latitude: selectedItem.latitude,
        longitude: selectedItem.longitude,
        selectedItemId: selectedItem.id,
      }).finally(() => {
        setLoading(false); // Ensure this gets set to false
      });
    }
  }, [selectedItem, fetchMatchingItems]);

  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/"); // Navigate to the home page
  };

  const handleItemSelected = (selectedValue) => {
    const selectedItemIndex = userItems.findIndex(
      (item) => item.id === selectedValue
    );
    if (selectedItemIndex !== -1) {
      setSelectedItem(userItems[selectedItemIndex]);
    }
  };

  // Inside Myitems component
  const handleDeleteClick = () => {
    // This should only open the confirmation dialog
    setOpenConfirmDialog(true);
  };

  // Call this function to close the dialog
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  // Call this function to confirm deletion
  const handleConfirmDelete = async () => {
    if (selectedItem) {
      try {
        console.log(
          "Calling deleteDocument function for item:",
          selectedItem.id
        ); // Log before calling the function
        await deleteDocument(selectedItem.id, selectedItem.status);
        handleCloseConfirmDialog(); // Close the dialog

        // Fetch the updated items list after deletion
        await fetchUserItems();

        // Determine the next selected item
        const nextSelectedItemIndex = userItems.findIndex(
          (item) => item.id !== selectedItem.id
        );
        const nextSelectedItem =
          userItems[nextSelectedItemIndex] || userItems[0] || null;

        // Update the selectedItem state
        setSelectedItem(nextSelectedItem);
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  return (
    <Container style={{ marginTop: "100px" }}>
      <Box sx={{ flexGrow: 1, my: 4 }}>
        <Grid
          container
          spacing={3}
          justifyContent={{ xs: "space-between", md: "flex-start" }}
        >
          {userItems.length > 0 && (
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: { s: "row", md: "column" },
                justifyContent: { xs: "space-between", md: "flex-start" }, // This will ensure the space is maximized between items on mobile view

                flexWrap: "wrap",
                "& > *": {
                  minWidth: { s: "50%", md: "100%" },
                  marginBottom: { s: 1, md: 2 },
                },
                alignItems: "center",
              }}
            >
              {/* Confirmation dialog */}
              <Dialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" className="dialogTextRed">
                  {"Confirm Deletion (Important - Read Carefully)"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    className="dialogTextRed"
                  >
                    Are you sure you want to delete this item? Please note the
                    platform tracks items reported and deleted to prevent abuse.
                    You are only allowed to report up to 2 lost items per 60
                    days.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseConfirmDialog} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleConfirmDelete} color="error" autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>

              {/* New section for selected item details */}

              <FormControl
                fullWidth
                margin="normal"
                sx={{ m: 1, minWidth: 120, boxShadow: 3, mt: 5 }}
              >
                <InputLabel id="select-item-label">Select Item</InputLabel>
                <Select
                  labelId="select-item-label"
                  id="select-item"
                  value={selectedItem ? selectedItem.id : ""}
                  label="Select Item"
                  onChange={(e) => handleItemSelected(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.light",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                      borderWidth: "2px",
                    },
                  }}
                >
                  {userItems.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Selected item details */}
              {selectedItem && (
                <Card sx={{ maxWidth: 345, mb: 2, mt: 4 }}>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {selectedItem.name}
                      <Typography variant="span" color="text.secondary">
                        {" "}
                        ({selectedItem.category})
                      </Typography>
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {selectedItem.location.slice(0, 12) +
                        (selectedItem.location.length > 12 ? "..." : "")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(selectedItem.date)}
                    </Typography>
                    {/* Add more details as you like */}
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      // onClick={handleDelete}
                      onClick={handleDeleteClick} // Use handleDeleteClick here
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              )}
              <LegendComponent />
            </Grid>
          )}

          <Grid item xs={12} md={9}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <FormControlLabel
                  label={showMap ? "Hide Map" : "Show Map"}
                  sx={{ minWidth: { s: "100%", sm: "auto" } }}
                  control={
                    <Switch
                      checked={showMap}
                      onChange={(e) => setShowMap(e.target.checked)}
                      classes={{
                        label: "switch-label", // Apply the switch label class
                        track: "switch-track", // Apply the switch track class
                        thumb: "switch-thumb", // Apply the switch thumb class
                      }}
                    />
                  }
                />
              </div>
            </Box>

            {showMap &&
              selectedItem &&
              typeof selectedItem.latitude === "number" &&
              typeof selectedItem.longitude === "number" &&
              !isNaN(selectedItem.latitude) &&
              !isNaN(selectedItem.longitude) && (
                <MapComponent
                  userLocation={{
                    latitude: selectedItem.latitude,
                    longitude: selectedItem.longitude,
                  }}
                  selectedItem={selectedItem}
                  itemsOfInterest={matchingItems}
                  source="MyItems"
                  loading={loading} // <-- Pass the loading state here
                />
              )}
            {
              <span className="infoTextRed">
                {" "}
                *Please note - Map pins are randomized within the metro area as
                exact location is not available in fb/craiglist/twitter. Do not
                rely on map pins for exact location within the city{" "}
              </span>
            }
          </Grid>

          <Grid
            item
            md={3}
            sx={{ display: { xs: "none", md: "block" } }}
          ></Grid>
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              order: { xs: 3, md: 2 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Add this line to center content vertically
            }}
          >
            {userItems.length === 0 ? (
              <Typography variant="h6">
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => {
                    navigateToHome();
                  }}
                >
                  <button className="button">
                    No Item - Please report an item on the home page
                  </button>
                </span>
              </Typography>
            ) : loading ? (
              <div>
                <l-line-spinner
                  size="80"
                  stroke="6"
                  speed="2"
                  color="#00a6fb"
                ></l-line-spinner>
              </div>
            ) : (
              <MyItemsTableComponent
                matchingItems={matchingItems}
                selectedItem={selectedItem}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Myitems;
