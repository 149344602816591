import { useState, useCallback, useEffect } from "react";

export function useGoogleAutocomplete() {
  const [autocompleteLocation, setAutocompleteLocation] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [country, setCountry] = useState("global"); // Default to "global"

  const extractCountry = (addressObject) => {
    console.log("Address Object:", addressObject); // Check what's being received

    const countryComponent = addressObject.address_components?.find(
      (component) => component.types.includes("country")
    );
    const extractedCountry = countryComponent
      ? countryComponent.long_name
      : "global";

    console.log("Extracted Country:", extractedCountry); // See what's being extracted
    return extractedCountry;
  };

  const handlePlaceSelect = useCallback(
    (autocomplete) => {
      console.log("handlePlaceSelect is triggered");
      try {
        const addressObject = autocomplete.getPlace();
        const locationValue = addressObject.formatted_address;
        const extractedCountry = extractCountry(addressObject); // Extract country

        const addressCoordinates = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
        };

        setAutocompleteLocation(locationValue);
        setCoordinates(addressCoordinates);
        setCountry(extractedCountry); // Set the extracted country
      } catch (error) {
        console.error("Error in handlePlaceSelect:", error);
      }
    },
    [setAutocompleteLocation, setCoordinates, setCountry] // Include setCountry in the dependencies array
  );

  useEffect(() => {
    if (coordinates.lat && coordinates.lng) {
      console.log("Updated coordinates", coordinates);
      console.log("Country:", country); // Log the country for debugging
    }
  }, [coordinates, country]); // Include country in the useEffect dependencies

  return {
    autocompleteLocation,
    coordinates,
    country, // Include country in the return value
    handlePlaceSelect,
    setAutocompleteLocation,
  };
}

export default useGoogleAutocomplete;
