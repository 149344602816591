import React, { useState } from "react";
import useLogin from "../../hooks/useLogin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import GoogleIcon from "@mui/icons-material/Google"; // If available
import FacebookIcon from "@mui/icons-material/Facebook"; // If available
import TwitterIcon from "@mui/icons-material/Twitter"; // If available

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, googleLogin, facebookLogin, twitterLogin, error, isPending } =
    useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>
          {error && <Typography color="error">{error}</Typography>}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <button
              onClick={googleLogin}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#4285F4", // Google's blue color
                color: "#fff", // White text
                border: "none",
                padding: "8px 12px", // Adjust padding to make it smaller
                borderRadius: "4px",
                cursor: "pointer",
                fontFamily: "Roboto, sans-serif", // Google's font
                fontWeight: "500", // Medium font weight
                fontSize: "12px", // Adjust font size to make it smaller
              }}
            >
              <GoogleIcon
                style={{
                  marginRight: "8px",
                  width: "20px", // Adjust the icon size
                  height: "20px", // Adjust the icon size
                }}
              />
              Sign in with Google
            </button>

            {/* <Button
              onClick={facebookLogin}
              color="primary"
              variant="contained"
              startIcon={<FacebookIcon />}
              sx={{ mx: 1 }}
            >
              Facebook
            </Button>
            <Button
              onClick={twitterLogin}
              color="info"
              variant="contained"
              startIcon={<TwitterIcon />}
            >
              Twitter
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
