import { useReducer, useEffect, useState, useCallback, useMemo } from "react";
import { projectFirestore, Timestamp } from "../firebase/config";
import { GeoPoint } from "firebase/firestore"; // Import GeoPoint from firestore
import { getFunctions, httpsCallable } from "firebase/functions";

import { geohashForLocation } from "geofire-common";

import { collection, addDoc, doc, getDoc } from "firebase/firestore";

let initialState = {
  addedDocument: null,
  document: null,
  userItems: [],
  matchingItems: [],
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { ...state, isPending: true, success: false, error: null };
    case "ADDED_DOCUMENT":
      return {
        ...state,
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "FETCHED_USER_ITEMS":
      return {
        ...state,
        isPending: false,
        userItems: action.payload.userItems,
        success: true,
        error: null,
      };
    case "FETCHED_MATCHING_ITEMS":
      return {
        ...state,
        isPending: false,
        matchingItems: action.payload.matchingItems,
        success: true,
        error: null,
      };
    case "ERROR":
      return {
        ...state,
        isPending: false,
        document: null,
        error: action.payload,
        success: false,
      };

    default:
      return state;
  }
};

export const useFirestore = (collectionName, userId) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  const dispatchIfNotCancelled = useCallback(
    (action) => {
      if (!isCancelled) {
        dispatch(action);
      }
    },
    [isCancelled, dispatch]
  ); // Now dispatch and isCancelled are dependencies

  const firebaseFunctions = getFunctions(projectFirestore.app, "europe-west2");

  // Updated addDocument function
  const addDocument = useCallback(
    async (doc) => {
      dispatch({ type: "IS_PENDING" });
      try {
        const addListing = httpsCallable(firebaseFunctions, "addListing");
        const result = await addListing(doc);
        dispatchIfNotCancelled({
          type: "ADDED_DOCUMENT",
          payload: result.data,
        });
      } catch (error) {
        let errorMessage = "Error adding document";
        if (error && error.details) {
          errorMessage = error.details;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        // Log the error message for debugging
        console.error("Error in addDocument:", errorMessage);

        dispatchIfNotCancelled({ type: "ERROR", payload: errorMessage });
      }
    },
    [dispatchIfNotCancelled, firebaseFunctions]
  );

  // Updated deleteDocument function
  const deleteDocument = useCallback(
    async ({ listingId, listingStatus }) => {
      dispatch({ type: "IS_PENDING" });
      try {
        const deleteListing = httpsCallable(firebaseFunctions, "deleteListing");
        console.log("Calling deleteListing function for listing:", listingId);
        const result = await deleteListing({ listingId, listingStatus });

        console.log("Document deleted:", result);
        dispatchIfNotCancelled({
          type: "DELETED_DOCUMENT",
          payload: listingId,
        });
      } catch (error) {
        console.error("Error deleting document:", error);
        dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      }
    },
    [dispatchIfNotCancelled, firebaseFunctions]
  );

  const fetchUserItems = useCallback(async () => {
    dispatch({ type: "IS_PENDING" });
    try {
      console.log("Calling listUserItems function for user:", userId); // Log before calling the function
      const listUserItems = httpsCallable(firebaseFunctions, "listUserItems");

      const result = await listUserItems({ userId }); // Call the function and pass the userId

      console.log("Fetched user items:", result.data); // Log the fetched data
      dispatch({ type: "FETCHED_USER_ITEMS", payload: result.data });
    } catch (error) {
      console.error("Error fetching user items:", error); // Log any errors
      dispatch({ type: "ERROR", payload: error.message });
    }
  }, [userId]);

  const fetchMatchingItems = useCallback(async (selectedItem) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const fetchMatchingItems = httpsCallable(
        firebaseFunctions,
        "fetchMatchingItems"
      );
      const result = await fetchMatchingItems(selectedItem);
      dispatch({ type: "FETCHED_MATCHING_ITEMS", payload: result.data });
    } catch (error) {
      // Handle custom errors with specific error codes and messages
      if (error.code === "invalid-argument") {
        console.error("Custom Error:", error.message);
        // Handle the error in your UI, e.g., display a message to the user
      } else {
        // Handle other errors
        console.error("Error:", error.message);
        // Handle the error in your UI, e.g., display a generic error message
      }
      dispatch({ type: "ERROR", payload: error.message });
    }
  }, []);

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    addDocument,
    deleteDocument,
    fetchUserItems,
    fetchMatchingItems,
    addedDocument: response.addedDocument,
    userItems: response.userItems,
    matchingItems: response.matchingItems,
    isPending: response.isPending,
    error: response.error,
    success: response.success,
  };
};
