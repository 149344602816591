import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue, get } from "firebase/database";
import { useAuthContext } from "../hooks/useAuthContext";

const MessageListComponent = ({
  onSelectConversation,
  selectedConversationId,
}) => {
  const [conversations, setConversations] = useState([]);
  const { user } = useAuthContext();

  // Function to get the other participant's ID
  const getOtherParticipantId = (participants) => {
    if (participants) {
      // Assuming 'participants' is an object where keys are user IDs and values are true
      return Object.keys(participants).find(
        (participantId) => participantId !== user.uid
      );
    }
    return null; // Handle the case when 'participants' is undefined
  };

  useEffect(() => {
    if (user && user.uid) {
      const db = getDatabase();
      const userConversationsRef = ref(db, `userConversations/${user.uid}`);

      const unsubscribe = onValue(userConversationsRef, (snapshot) => {
        const userConvoIds = snapshot.val();
        if (userConvoIds) {
          const convos = Object.keys(userConvoIds).map((convoId) => {
            return get(ref(db, `conversations/${convoId}`)).then(
              (convoSnapshot) => {
                return { id: convoId, ...convoSnapshot.val() };
              }
            );
          });
          Promise.all(convos).then((convosDetails) => {
            // Filter out conversations where there is no other user
            const filteredConvos = convosDetails.filter((conversation) => {
              const otherUserId = getOtherParticipantId(
                conversation.participants
              );
              return otherUserId !== null; // Filter out if otherUserId is null (no other user)
            });

            setConversations(filteredConvos);

            // Check if there are conversations and no conversation is selected
            if (filteredConvos.length > 0 && !selectedConversationId) {
              // Select the latest conversation
              onSelectConversation(filteredConvos[0].id);
            }
          });
        } else {
          setConversations([]);
        }
      });

      return () => unsubscribe();
    }
  }, [user, selectedConversationId, onSelectConversation]);

  const getUsernameFromEmail = (email) => {
    if (email) {
      return email.substring(0, 4) + "xxxxxx";
    }
    return "Unknown"; // Provide a default value if email is null or undefined
  };

  return (
    <div className="flex flex-col h-screen bg-white w-80 border-r border-gray-300">
      {/* <div className="flex items-center justify-between p-4 border-b-2 bg-indigo-600 text-white">
        <span className="text-lg font-bold">Conversations</span>
        <span className="text-sm">{conversations.length}</span>
      </div> */}
      <div className="overflow-y-auto">
        {conversations.length > 0 ? (
          conversations.map((conversation) => {
            const otherUserId = getOtherParticipantId(
              conversation.participants
            );
            const otherUserName = getUsernameFromEmail(otherUserId);
            const isSelected = selectedConversationId === conversation.id; // Check if it's selected

            return (
              <div
                key={conversation.id}
                onClick={() => onSelectConversation(conversation.id)}
                style={{
                  backgroundColor: isSelected ? "#00a6fb" : "transparent",
                }} // Temporary inline style
                className={`cursor-pointer p-3 mt-4 flex items-center transition duration-300 ease-in-out`}
              >
                <img
                  src={`https://eu.ui-avatars.com/api/?name=${otherUserName}&color=00a6fb&background=333333&size=250`}
                  alt={`${otherUserName}'s avatar`}
                  style={{ width: "30px", height: "30px" }}
                  className="rounded-full mr-2"
                />
              </div>
            );
          })
        ) : (
          <div className="p-3 text-gray-500 mt-3">
            No conversations available.
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageListComponent;
