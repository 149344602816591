import React, { useState } from "react";
import { getDatabase, ref, push, set } from "firebase/database";
import { useAuthContext } from "../hooks/useAuthContext";

const MessageInputComponent = ({
  conversationId,
  setConversationId,
  receiverId,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const { user } = useAuthContext();

  const sendMessage = async () => {
    const db = getDatabase();

    try {
      if (!newMessage.trim()) {
        // Check if the newMessage is empty or contains only whitespace
        return; // If empty, do not send the message
      }

      if (!conversationId && user && receiverId) {
        // Handle new conversation creation
        const newConversationRef = push(ref(db, "conversations"));
        const newConversationId = newConversationRef.key;

        await set(newConversationRef, {
          participants: {
            [user.uid]: true,
            [receiverId]: true,
          },
          messages: {},
        });

        // Send the message to the new conversation
        const messagesRef = ref(
          db,
          `conversations/${newConversationId}/messages`
        );
        await push(messagesRef, {
          text: newMessage,
          timestamp: Date.now(),
          senderId: user.uid,
        });

        // Update conversationId for future messages through the state updater function
        setConversationId(newConversationId);
      } else if (conversationId) {
        // Send message to existing conversation
        const messagesRef = ref(db, `conversations/${conversationId}/messages`);
        await push(messagesRef, {
          text: newMessage,
          timestamp: Date.now(),
          senderId: user.uid,
        });
      }
    } catch (error) {
      console.error("Failed to send message: ", error);
      // Handle errors, e.g., show a notification to the user
    }

    setNewMessage(""); // Clear the input field after sending the message
  };

  return (
    <div className="message-input-container">
      <input
        type="text"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type a message..."
        className="message-input-field"
      />
      <button onClick={sendMessage} className="send-button">
        Send
      </button>
    </div>
  );
};

export default MessageInputComponent;
