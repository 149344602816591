import React from "react";
import "./LegendComponent.css";

const Legend = () => {
  return (
    <div className="legend">
      <div className="legend-item">
        <img
          className="legend-icon"
          src="1_icon.png" // Update the path to your Twitter icon
          alt="Twitter Icon"
        />
        <span className="legend-text ml-2">Twitter Posts</span>
      </div>
      <div className="legend-item">
        <img
          className="legend-icon"
          src="100_icon.png" // Update the path to your Refind icon
          alt="Refind Icon"
        />
        <span className="legend-text ml-2">Reported on Refind</span>
      </div>
      <div className="legend-item">
        <img
          className="legend-icon"
          src="2_icon.png" // Update the path to your Facebook icon
          alt="Facebook Icon"
        />
        <span className="legend-text ml-2">Facebook Posts</span>
      </div>

      <div className="legend-item">
        <img
          className="legend-icon"
          src="3_icon.png" // Update the path to your Craiglist icon
          alt="Craiglist Icon"
        />
        <span className="legend-text ml-2">Craiglist Lost & Found</span>
      </div>
    </div>
  );
};

export default Legend;
