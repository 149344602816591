import { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";

export function useGoogleApi() {
  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyAXLBSthZl5Djzet6p6PsJwwvFkkdwXGl0", // Replace with your API key
      version: "weekly",
      libraries: ["places"], // Include any libraries you need, like 'places'
    });

    loader.load().then(() => {
      setApiLoaded(true);
    });
  }, []);

  return apiLoaded;
}
