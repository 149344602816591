import React, { useEffect } from "react";

const FlourishEmbed = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://public.flourish.studio/resources/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="flourish-embed flourish-map"
      data-src="visualisation/16321169"
    ></div>
  );
};

export default FlourishEmbed;
