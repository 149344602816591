import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useGoogleMap } from "../hooks/useGoogleMap";
import { lineSpinner } from "ldrs";

export default function MapComponent({
  selectedItem,
  userLocation,
  itemsOfInterest,
  source,
  loading,
}) {
  lineSpinner.register();
  const { latitude = 51.5074, longitude = -0.1278 } = userLocation || {};

  const { mapRef, map } = useGoogleMap({ latitude, longitude });
  const markersRef = useRef([]);

  // Define custom styles
  const customMapStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
  ];

  useEffect(() => {
    if (map) {
      map.setOptions({ styles: customMapStyles });
    }
  }, [map]);

  useEffect(() => {
    if (!map || !Array.isArray(itemsOfInterest)) return;

    const clearMarkers = () => {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];
    };

    clearMarkers();
    map.setCenter(new window.google.maps.LatLng(latitude, longitude));
    const zoomLevel = 12;
    map.setZoom(zoomLevel);

    let userMarkerInfoWindow = null;

    if (selectedItem) {
      const userMarkerContentString = `
        <div style="width: 200px; font-family: Arial, sans-serif; padding: 10px; border-radius: 8px; box-shadow: 0 2px 6px rgba(0,0,0,0.3); background: #fff;">
          <h1 style="font-size: 14px; margin: 0; padding: 0; color: #000;">Your/Item Location</h1>
          ${
            selectedItem.imageUrl
              ? `<div style="text-align: center; margin-bottom: 8px;">
                    <img src="${selectedItem.imageUrl}" alt="Preview" style="max-width: 60%; border-radius: 4px;"/>
                   </div>`
              : ""
          }
          <p style="font-size: 12px; margin: 5px 0; color: #555;">Category: ${
            selectedItem.category
          }</p>
          <p style="font-size: 12px; margin: 5px 0; color: #555;">Relevance: ${
            selectedItem.status === 0 ? "Lost" : "Found"
          }</p>
          <p style="text-align: center; margin: 10px 0;">
            <a href="${
              selectedItem.url
            }" target="_blank" style="text-decoration: none; color: #4285f4; font-size: 12px;">View More</a>
          </p>
        </div>
      `;

      // Create an info window for the userMarker
      userMarkerInfoWindow = new window.google.maps.InfoWindow({
        content: userMarkerContentString,
      });
    }

    // Create the userMarker (blue marker)
    const userMarker = new window.google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: map,
      title: "Your/Item Location",
      icon: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
      zIndex: 9999,
    });

    // Add a click event listener to the userMarker to open the info window if it exists
    if (userMarkerInfoWindow) {
      userMarker.addListener("click", () => {
        userMarkerInfoWindow.open(map, userMarker);
      });
    }

    markersRef.current.push(userMarker);

    itemsOfInterest.forEach((item) => {
      if (!item || !item.latitude || !item.longitude) {
        return;
      }

      const itemLat = item.latitude;
      const itemLng = item.longitude;

      let markerIcon;

      // Check the source value and set the marker icon path accordingly
      // Check the source value and set the marker icon path accordingly
      if (item.source === 1) {
        markerIcon = {
          url: "1_icon.png",
          scaledSize: new window.google.maps.Size(24, 30), // Scale the icon
          anchor: new window.google.maps.Point(10, 10), // Set the anchor point
        };
      } else if (item.source === 2) {
        markerIcon = {
          url: "2_icon.png",
          scaledSize: new window.google.maps.Size(24, 30),
          anchor: new window.google.maps.Point(10, 10),
        };
      } else if (item.source === 3) {
        markerIcon = {
          url: "3_icon.png",
          scaledSize: new window.google.maps.Size(24, 30),
          anchor: new window.google.maps.Point(10, 10),
        };
      } else {
        markerIcon = {
          url: "100_icon.png",
          scaledSize: new window.google.maps.Size(24, 30),
          anchor: new window.google.maps.Point(10, 10),
        };
      }

      const marker = new window.google.maps.Marker({
        position: { lat: itemLat, lng: itemLng },
        map: map,
        title: item.category,
        icon: markerIcon,
      });
      const contentString = `
        <div style="max-width: 250px; font-family: Arial, sans-serif; padding: 10px; border-radius: 8px; box-shadow: 0 2px 6px rgba(0,0,0,0.3); background: #b8f2e6;">
          <div style="display: flex; align-items: center; margin-bottom: 8px;">
            <img src="${
              item.source === 1
                ? "twitter_logo.png"
                : item.source === 2
                ? "landing_fb_logo.png"
                : "cl_logo.png"
            }" alt="Profile" style="width: 30px; height: 30px; border-radius: 50%;"/>
            <div style="margin-left: 8px;">
              <div style="font-size: 14px; font-weight: bold;">${
                item.source === 1
                  ? "Twitter User"
                  : item.source === 2
                  ? "Facebook User"
                  : "Craigslist User"
              }</div>
              <div style="font-size: 12px; color: #555;">@${
                item.source === 1
                  ? "twitter"
                  : item.source === 2
                  ? "facebook"
                  : "craigslist"
              }</div>
            </div>
          </div>
          <div style="font-size: 14px; margin-bottom: 8px;">${
            item.description
          }</div>
          ${
            item.imageUrl
              ? `
            <div style="text-align: center; margin-bottom: 8px;">
            <img src="${item.imageUrl}" alt="Attached Image" style="max-width: 60%; border-radius: 4px;"/>
            </div>`
              : ""
          }
          <div style="font-size: 12px; color: #555; margin-bottom: 8px;">${
            item.date &&
            (typeof item.date === "object" &&
            item.date.hasOwnProperty("_seconds") &&
            item.date.hasOwnProperty("_nanoseconds")
              ? new Date(
                  item.date._seconds * 1000 + item.date._nanoseconds / 1000000
                ).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
              : item.date.toString().slice(0, 11)) // Slice the first 15 characters if it's a string
          }</div>
          <div style="text-align: center;">
            <a href="${
              item.url
            }" target="_blank" style="text-decoration: none; color: ${
        item.source === 1 ? "#1DA1F2" : "#4267B2"
      }; font-size: 12px;">Connect on ${
        item.source === 1
          ? "Twitter"
          : item.source === 2
          ? "Facebook"
          : "Craigslist"
      }</a>
          </div>
        </div>
      `;

      const infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });

      markersRef.current.push(marker);
    });
    return () => clearMarkers();
  }, [
    map,
    itemsOfInterest,
    userLocation,
    latitude,
    longitude,
    selectedItem,
    source,
  ]);

  return (
    <Box
      ref={mapRef}
      sx={{
        width: "100%",
        height: "450px",
        position: "sticky",
        top: 0,
      }}
    />
  );
}
