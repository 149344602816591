import React, { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

import {
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  TablePagination,
  Link,
} from "@mui/material";

// Icons
import MessageIcon from "@mui/icons-material/Message";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import "./MyItemsTableComponent.css"; // Import a CSS file for styling

function MyItemsTableComponent({ matchingItems, selectedItem }) {
  // Sort matchingItems by date, most recent first
  matchingItems.sort((a, b) =>
    b.date ? b.date._seconds - a.date._seconds : 0
  );

  const { user } = useAuthContext();

  const navigate = useNavigate();

  const navigateToMessages = (currentUserId, otherUserId) => {
    navigate(`/messages?User2=${otherUserId}`);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - matchingItems.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const getSourceIcon = (item) => {
  //   let icon;
  //   console.log(item.source);

  //   try {
  //     switch (item.source) {
  //       case 1:
  //         icon = <TwitterIcon />;
  //         break;
  //       case 2:
  //         icon = <FacebookIcon />;
  //         break;
  //       case 3:
  //         // Custom image for Craigslist
  //         icon = (
  //           <a href={item.url} target="_blank" rel="noopener noreferrer">
  //             <img
  //               src="cl_logo.png"
  //               alt="Craigslist icon"
  //               style={{ width: "28px", height: "28px" }}
  //             />
  //           </a>
  //         );
  //         break;
  //       default:
  //         icon = (
  //           <IconButton
  //             onClick={() => {
  //               if (item.uid) {
  //                 navigateToMessages(user.uid, item.uid);
  //               }
  //             }}
  //             style={{ color: "#292929" }}
  //           >
  //             <MessageIcon />
  //           </IconButton>
  //         );
  //         break;
  //     }
  //   } catch (error) {
  //     console.error("Error generating icon:", error);
  //   }

  //   return (
  //     <div className="radar-effect">
  //       {item.source ? (
  //         <Link href={item.url} target="_blank" rel="noopener noreferrer">
  //           {icon}
  //         </Link>
  //       ) : (
  //         icon
  //       )}
  //     </div>
  //   );
  // };

  const getSourceIcon = () => {
    return (
      <div className="radar-effect">
        <MessageIcon />
      </div>
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="my-items-table-container">
      <Paper>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
        >
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "#292929", color: "#fff" }}>
              <TableRow>
                <TableCell style={{ color: "#fff" }}>Connect</TableCell>
                <TableCell style={{ color: "#fff" }}>Date</TableCell>
                <TableCell style={{ color: "#fff" }}>Description</TableCell>
                <TableCell style={{ color: "#fff" }}>Image</TableCell>
                <TableCell style={{ color: "#fff" }}>Category</TableCell>
                <TableCell style={{ color: "#fff" }}>Distance</TableCell>
                <TableCell style={{ color: "#fff" }}>Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? matchingItems.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : matchingItems
              ).map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <div className="icon-container">
                      {" "}
                      {/* Same wrapper for both */}
                      {item.source === 1 ||
                      item.source === 2 ||
                      item.source === 3 ? (
                        // For external sources, use Link to navigate to item.url
                        <Link
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getSourceIcon(item.source)}
                        </Link>
                      ) : (
                        // For internal items, open messages in a new window
                        <IconButton
                          onClick={() => {
                            if (item.uid) {
                              window.open(
                                `/messages?User2=${item.uid}`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {getSourceIcon(item.source)}
                        </IconButton>
                      )}
                    </div>
                  </TableCell>

                  <TableCell style={{ fontSize: "small" }}>
                    {
                      item.date &&
                        (typeof item.date === "object" &&
                        item.date.hasOwnProperty("_seconds") &&
                        item.date.hasOwnProperty("_nanoseconds")
                          ? new Date(
                              item.date._seconds * 1000 +
                                item.date._nanoseconds / 1000000
                            ).toLocaleDateString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                          : item.date.toString().slice(0, 11)) // Slice the first 15 characters if it's a string
                    }
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "small",
                    }}
                  >
                    {item.description.length > 30
                      ? `${item.description.slice(0, 30)}...`
                      : item.description}
                  </TableCell>
                  <TableCell>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar
                        alt={item.name}
                        src={
                          item.imageUrl && item.imageUrl !== "nil"
                            ? item.imageUrl
                            : `/${item.source}_icon.png`
                        }
                        style={{
                          width: "24px",
                          height: "32px",
                          objectFit: "contain",
                        }} // Set width and height
                        variant={item.imageUrl ? "rounded" : undefined}
                      />
                    </a>
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell style={{ fontSize: "small" }}>
                    {`${(item.distance / 1000).toFixed(1)} km`}
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: "small",
                    }}
                  >
                    {item.location.length > 10
                      ? `${item.location.slice(0, 10)}...`
                      : item.location}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={matchingItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default MyItemsTableComponent;
