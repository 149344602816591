import { useState, useRef, useEffect } from "react";
import { useGoogleApi } from "./useGoogleApi";

export function useGoogleMap(initialCoords) {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const apiLoaded = useGoogleApi();

  useEffect(() => {
    if (apiLoaded && mapRef.current && !map) {
      const googleMap = new window.google.maps.Map(mapRef.current, {
        center: initialCoords,
        zoom: 12,
      });

      setMap(googleMap);
    }
  }, [apiLoaded, map, initialCoords]);

  return { mapRef, map };
}
