import { useState, useEffect } from "react";
import { projectAuth } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useGoogleAuth } from "./useGoogleAuth";
// import { useTwitterAuth } from "./future hooks/UseTwitterAuth";
// import { useFacebookAuth } from "./future hooks/useFacebookAuth";

const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  // These hooks are called here at the top level of your custom hook
  const { googleLogin } = useGoogleAuth();
  // const { facebookLogin } = useFacebookAuth();
  // const { twitterLogin } = useTwitterAuth();

  const login = async (email, password) => {
    setIsPending(true);
    setError(null);

    try {
      const res = await signInWithEmailAndPassword(
        projectAuth,
        email,
        password
      );

      // dispatch login action
      dispatch({
        type: "LOGIN",
        payload: res.user,
      });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
      }
      setIsPending(false);
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  // Also return the google, facebook, and twitter login functions
  return { error, isPending, login, googleLogin };
  // return { error, isPending, login, googleLogin, facebookLogin, twitterLogin };
};

export default useLogin;
